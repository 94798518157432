/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'stop': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<rect pid="0" width="12" height="12" rx="2" _fill="#fff"/>'
  }
})
